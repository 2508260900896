import React from 'react'

import { Box, Container, Flex } from '@components/Grid'
import { SectionTitle, Text } from '@components/Text'

const Content = () => (
  <Box as="section" bg="green.100" py={6} px={4} id="services">
    <Container>
      <SectionTitle color="primary.900">TERMS OF USE</SectionTitle>
      <Box bg="white" position="relative" px={6} py={6} zIndex="2" width="100%">
        <Text as="h3" color="primary.900">
          1. Headquarters
        </Text>
        <Text variant="body">
          Business Affiliation Europe, S.L.
          <br />
          In compliance with Law 34/2002 on Services of the Information Society
          and Electronic Commerce of Spain, we inform you that this website is
          the property of Business Affiliation Europe, S.L., its identifying
          data being:
          <br />
          Address: Avenida Brasil Nº17 Entreplanta, 28020 Madrid
          <br />
          Telephone: +34 955 44 19 90
          <br />
          Email: info@aibrok.com.mx
        </Text>
        <Text variant="body">
          Business Affiliation Europe, SL, is an exclusive insurance agency
          registered in the Administrative Registry of insurance and reinsurance
          distributors of the General Directorate of Insurance and Pension Funds
          with the code C0031B88229752 and in the Madrid Mercantile Registry at
          Volume 38382, Folio 155 , Sheet M-682866 and CIF B-88229752. It is
          also an agent of one of the Investment Services Companies (ESI) that
          is in the National Securities Market Commission (CNMV). It is also
          stated that Business Affiliation Europe, S.L, has subscribed a
          professional civil liability insurance and a surety insurance to
          guarantee its financial capacity.
        </Text>
        <Text as="h3" color="primary.900">
          2. Terms and conditions of use
        </Text>
        <Text variant="body">
          Business Affiliation Europe, S.L. is the owner of the website:
          www.aibrok.com.mx and informs you that the access and use of the page
          and all the subdomains and directories included under it (hereinafter,
          jointly referred to as the "Aibrok Portal") , as well as the services
          that can be obtained through it, are subject to the terms detailed in
          this Legal Notice.
        </Text>
        <Text variant="body">
          Therefore, if the considerations detailed in this Legal Notice do not
          agree with you, please do not use the Aibrok Portal, since any use you
          make of it or the Services included in it will imply acceptance of the
          legal terms set out. in this text.
        </Text>
        <Text variant="body">
          Business Affiliation Europe, S.L. reserves the right to make changes
          to the Aibrok Portal without prior notice, in order to update,
          correct, modify, add or delete the contents of the Aibrok Portal or
          its design.
        </Text>
        <Text variant="body">
          The conditions and terms included in this Legal Notice may vary, so we
          invite you to review these terms when you visit the Aibrok Portal
          again.
        </Text>
        <Text as="h3" color="primary.900">
          3. Exclusion of liability
        </Text>
        <Text variant="body">
          Business Affiliation Europe, S.L. excludes without reservation all
          liability for loss or damage of any kind, whether for direct, indirect
          or consequential damages, which may result from the use of or access
          to the Aibrok Portal.
        </Text>
        <Text variant="body">
          The information published on the Aibrok Portal does not constitute any
          recommendation, offer or invitation to purchase or sell insurance or
          investment instruments, or to carry out any other type of transaction.
        </Text>
        <Text variant="body">
          Before making any decision, Aibrok recommends taking the advice of our
          qualified specialists.
        </Text>
        <Text as="h3" color="primary.900">
          4. Intellectual property
        </Text>
        <Text variant="body">
          The Company is the owner of the intellectual and industrial property
          rights of the Aibrok Portal, its software, logos, brands, trade names,
          content, expressly prohibiting the exploitation, reproduction,
          copying, duplication, distribution, modification, public
          communication, commercialization, assignment or transformation or any
          other activity that can be carried out with them, without prior
          written permission from Business Affiliation Europe, SL.
        </Text>
        <Text variant="body">© Business Affiliation Europe S.L. 2021</Text>
      </Box>
    </Container>
  </Box>
)

export default Content
