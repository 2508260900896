import React from 'react'
import { navigate } from 'gatsby';

import { Layout } from '@components/LayoutEN'
import { SEO } from '@components/SEO'
import { Content } from '../screens/TermsEN'

const TermsPage = () => {

  if (typeof window !== 'undefined' && window.location.pathname === '/termsConditions') {
    navigate('/es/termsConditions');
  }

  return (
    <Layout>
      <SEO title="Terms & Conditions" />
      <Content />
    </Layout>
  )
}

export default TermsPage
